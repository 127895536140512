import FirestoreDataConverter from "./base/data-converter";

export class ServiceInfo {
  preReads: string;
  deliverables?: string;
  modalSummary?: string;
  function?: string;
  topics?: string[];
  smsHashtag?: string[];
  canScheduleMeeting?: boolean;
  airtableId?: string;

  constructor(
    preReads: string,
    deliverables?: string,
    modalSummary?: string,
    builderFunction?: string, // Can't have function as a parameter name.
    topics?: string[],
    smsHashtag?: string[],
    canScheduleMeeting?: boolean,
    airtableId?: string,
  ) {
    // Both preReads and deliverables are html rich text.
    this.preReads = preReads;
    this.deliverables = deliverables;
    this.modalSummary = modalSummary;
    this.function = builderFunction;
    this.topics = topics;
    this.smsHashtag = smsHashtag;
    this.canScheduleMeeting = canScheduleMeeting;
    this.airtableId = airtableId;
  }
}

export const serviceInfoDataConverter: FirestoreDataConverter<ServiceInfo> = {
  toFirestoreModel: function (builderInfo: ServiceInfo) {
    return {
      pre_reads: builderInfo.preReads,
      deliverables: builderInfo.deliverables,
      modal_summary: builderInfo.modalSummary,
      function: builderInfo.function,
      topics: builderInfo.topics,
      sms_hash_tag: builderInfo.smsHashtag,
      can_schedule_meeting: builderInfo.canScheduleMeeting,
      airtable_id: builderInfo.airtableId,
    };
  },
  fromFirestoreModel: function (data): ServiceInfo {
    return new ServiceInfo(
      data.pre_reads,
      data.deliverables,
      data.modal_summary,
      data.function,
      data.topics,
      data.sms_hash_tag,
      data.can_schedule_meeting,
      data.airtable_id,
    );
  },
};
