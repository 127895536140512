export type ConnectionsPage<T> = {
  airtableAppId: string;
  data?: T[];
};

export type NetworkConnection = {
  airtableId: string;
  name: string;
  bio?: string;
  image?: string;
  linkedin?: string;
  function?: string;
  topics?: string[];
  email?: string;
};

export type Builder = NetworkConnection & {
  history?: string;
  website?: string;
  profileId?: string; // Ampersand user ID
  /** Keyed by airtableId */
  vouchesById?: Record<string, Vouch>;
  vouches?: Vouch[];
};

export type Agency = NetworkConnection & {
  type: "Agency" | "Freelancer";
  history?: string;
  website?: string;
  /** Keyed by airtableId */
  vouchesById?: Record<string, Vouch>;
  vouches?: Vouch[];
};

export type SequoiaTeam = NetworkConnection & {
  profileId?: string; // Ampersand user ID
  preReads?: string;
  deliverables?: string;
  canScheduleMeeting: boolean;
};

export type Vouch = {
  airtableId: string;
  name: string;
  profileId?: string; // Ampersand user ID
  image?: string;
  title?: string;
  quote?: string;
};

export const CONNECTIONS_DATA_VERSION = 3;
export const CONNECTIONS_HOSTING_BASE = `resource/data/v${CONNECTIONS_DATA_VERSION}`;

export const BUILDER_CONNECTIONS_DATA_PATH = `${CONNECTIONS_HOSTING_BASE}/builder-connections-data.json`;
export const AGENCY_CONNECTIONS_DATA_PATH = `${CONNECTIONS_HOSTING_BASE}/agency-connections-data.json`;
export const SEQUOIA_CONNECTIONS_DATA_PATH = `${CONNECTIONS_HOSTING_BASE}/sequoia-connections-data.json`;
