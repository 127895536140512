import { StorageTimestamp } from "./base/data-type";
import FirestoreDataConverter from "./base/data-converter";

export const ONE_ON_ONE_SERVICE_ID = "1-1";

export class ServiceRequest {
  id: string;
  createdAt: StorageTimestamp;
  createdById: string;
  serviceId: string;
  // special case for 1 - 1 request, serviceId will be "1-1"
  sequoiaProfileId?: string;
  requestData?: string;
  smsNoteId?: string;

  constructor(
    id: string,
    createdAt: StorageTimestamp,
    createdById: string,
    serviceId: string,
    sequoiaProfileId?: string,
    requestData?: string,
    smsNoteId?: string,
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.createdById = createdById;
    this.serviceId = serviceId;
    this.sequoiaProfileId = sequoiaProfileId;
    this.requestData = requestData;
    this.smsNoteId = smsNoteId;
  }
}

export const serviceRequestDataConverter: FirestoreDataConverter<ServiceRequest> =
  {
    toFirestoreModel: function (serviceRequest: ServiceRequest) {
      return {
        id: serviceRequest.id,
        created_at: serviceRequest.createdAt,
        created_by_id: serviceRequest.createdById,
        service_id: serviceRequest.serviceId,
        sequoia_profile_id: serviceRequest.sequoiaProfileId,
        request_data: serviceRequest.requestData,
        sms_note_id: serviceRequest.smsNoteId,
      };
    },
    fromFirestoreModel: function (data): ServiceRequest {
      return new ServiceRequest(
        data.id,
        data.created_at,
        data.created_by_id,
        data.service_id,
        data.sequoia_profile_id,
        data.request_data,
        data.sms_note_id,
      );
    },
  };
